.Home {
  background: #3F9AF7;
}

.Home .content {
  margin: 0 auto;
  max-width: 1100px;
  padding: 150px 10px 200px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .Home .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

.Home h1 {
  margin: 0 auto 50px;
  padding: 0 15px;
}

.Home h2 {
  margin: -40px auto 40px;
  padding: 0 15px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .Home .content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
