.FinanceDispatch {
  background: rgb(225, 112, 85);
}

.FinanceDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .FinanceDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.FinanceDispatch .Hero {
  background: linear-gradient(rgb(225, 112, 85), rgb(171, 77, 55));
}

.FinanceDispatch .Hero .content {
  margin: 10px auto 50px;
}

.FinanceDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.FinanceDispatch .Background {
  left: 30%;
}

.FinanceDispatch .Background circle {
  fill: rgb(225, 112, 85);
}

/* Start */

.FinanceDispatch .Start {
  background: #fafafa;
}

.FinanceDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .FinanceDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}
