.ValetDispatch {
  background: rgb(40, 128, 186);
}

.ValetDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .ValetDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.ValetDispatch .Hero {
  background: linear-gradient(rgb(40, 128, 186), rgb(21, 91, 138));
}

.ValetDispatch .Hero .content {
  margin: 10px auto 50px;
}

.ValetDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.ValetDispatch .Background {
  left: 30%;
}

.ValetDispatch .Background circle {
  fill: rgb(40, 128, 186);
}

/* Start */

.ValetDispatch .Start {
  background: #fafafa;
}

.ValetDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .ValetDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}
