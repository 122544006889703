.Background {
  width: 2000px;
  height: 1500px;
  position: absolute;
  left: 40%;
  right: 0;
  bottom: 0;
  transform: rotate(-20deg);
  z-index: -1;
}

.Background circle {
  fill: #3F9AF7;
  opacity: .15;
}
