.Solutions {
  background: #fafafa;
}

.Solutions .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .Solutions .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

.Solutions > .content > h1,
.Solutions > .content > h2 {
  padding: 0 15px;
}

.Solutions > .content > h2 {
  margin: 10px 0 70px;
}

.Solutions .Start .content {
  padding-bottom: 150px;
}

/* Solution Item Styles */

.SolutionItems .item h2 {
  border-bottom: 3px solid;
  margin-bottom: 15px;
}


.SolutionItems #finance_dispatch h2 { border-color: rgb(225, 112, 85); }
.SolutionItems #finance_dispatch:hover .learn { color: rgb(225, 112, 85); }

.SolutionItems #service_drive_dispatch h2 { border-color: rgb(39, 174, 96); }
.SolutionItems #service_drive_dispatch:hover .learn { color: rgb(39, 174, 96); }

.SolutionItems #up_system h2 { border-color: rgb(230, 126, 34); }
.SolutionItems #up_system:hover .learn { color: rgb(230, 126, 34); }

.SolutionItems #valet_dispatch h2 { border-color: rgb(40, 128, 186); }
.SolutionItems #valet_dispatch:hover .learn { color: rgb(40, 128, 186); }


.SolutionItems #wash_rack h2 { border-color: rgb(199, 131, 227); }
.SolutionItems #wash_rack:hover .learn { color: rgb(199, 131, 227); }


/* Linked Items */

.SolutionItems a.item {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.SolutionItems a.item .learn {
  color: #777;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  line-height: 27px;
  position: relative;
  padding: 3px 3px 0 10px;
  transition: color .2s;
}

.SolutionItems a.item .learn::after {
  content: "";
  height: 4px;
  width: 4px;
  border-top: 1px solid;
  border-right: 1px solid;
  display: inline-block;
  transform: rotate(45deg)
    translateY(-50%);
}

.SolutionItems .item .learn:hover { text-decoration: underline; }

/* Bullet Points */

.SolutionItems .item p {
  padding: 0 0 5px 10px;
  margin: 0;
  position: relative;
  line-height: normal;
}

.SolutionItems .item p::before {
  content: "•";
  position: absolute;
  left: 0;
}

/* Spacer Items */

.SolutionItems .item.link,
.SolutionItems .item.spacer {
  min-height: 166px;
}

.SolutionItems .item.link h2 {
  border: none;
  margin: 0;
}

.SolutionItems .item.spacer {
  visibility: hidden;
}
