@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,800);
*, a, a * {
  outline: none;
  border: none;
}

body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Header {
  padding: 20px 25px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  fill: #fff;
  font-size: 14px;
  z-index: 100;
}

@supports (padding: env(safe-area-inset-top)) {
  .Header {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
  }
}

.darkHeader + .Header {
  color: #2c3e50;
  fill: #2c3e50;
}

.Header .content {
  height: 30px;
  max-width: 1200px;
  margin: auto;
  position: relative;
}

/*** Logo ***/

.Header .logo {
  display: inline-block;
  height: 30px;
  text-decoration: inherit;
  color: inherit;
}

.Header .icon {
  height: 30px;
  width: 30px;
  vertical-align: middle;
}

.Header .icon .outer,
.darkHeader + .Header .icon .inner {
  fill: #fff;
}

.Header .icon .inner {
  fill: none;
}

.darkHeader + .Header .icon .outer {
  fill: #3F9AF7;
}

.Header .title {
  font-weight: 600;
  font-size: 22px;
  margin-left: 10px;
  vertical-align: middle;
}

/*** Print View Links ***/

.PrintViewLinks {
  display: none;
  margin: 7px 0 0;
}

.PrintViewLinks a {
  display: block;
  text-align: right;
  margin: 1px 0 0;
  font-size: 12.29px;
  color: inherit;
  text-decoration: none;
}

.PrintViewLinks a:last-child::before {
  content: "hello";
}

/*** Menu ***/

.Header .open,
.Header .close {
  height: 30px;
  width: 30px;
  fill: inherit;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.Header .menu {
  display: inline-block;
  float: right;
  height: 30px;
}

/*** Links ***/

.Header .links {
  display: inline-block;
  height: 100%;
}

.Header .links a {
  color: inherit;
  text-decoration: inherit;
  line-height: 30px;
  margin-left: 30px;
  transition: color .2s;
  border-bottom: 1px solid rgba(255,255,255,0);
  transition: border-color .2s;
}

.Header .links a:hover,
.Header .links a.active,
.Header .links a.active {
  border-color: inherit;
}

.Header .links a span {
  display: none;
}

/*** Login Button ***/

.Header .login {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 15px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
}

.darkHeader + .Header .login {
  border-color: #2c3e50;
}

.Header .login:hover {
  color: #3F9AF7;
  background: #fff;
}

.darkHeader + .Header .login:hover {
  color: #fff;
  background: #2c3e50;
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  /*** Menu ***/

  .Header .open {
    display: inline;
    display: initial;
  }

  .Header .menu {
    display: none;
    float: none;
    height: auto;
    background: #fff;
    color: #000;
    position: absolute;
    top: -10px;
    left: -15px;
    right: -15px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1),
      0 5px 25px 0 rgba(0,0,0,.5),
      0 2px 2px 0 rgba(0,0,0,.1);
  }

  .Header .menu.show {
    display: block;
  }

  .Header .menu .close {
    fill: #aaa;
    top: 10px;
    right: 15px;
    display: inline;
    display: initial;
  }

  /*** Links ***/

  .Header .links {
    display: block;
    height: auto;
    padding: 40px 30px 30px;
    font-size: 16px;
  }

  .Header .links a,
  .Header .links a:hover,
  .Header .links a.active,
  .Header .links a.active {
    display: block;
    margin: 0;
    line-height: normal;
    font-weight: 600;
    padding: 15px;
    border-top: 1px solid rgba(0,0,0,0.05);
    border-bottom: 0;
    transition: border-color 0s;
  }

  .Header .links a.active {
    background: rgba(63, 154, 247, .1);
    border-radius: 3px;
  }

  .Header .links a:first-of-type,
  .Header .links a.active,
  .Header .links a.active + a {
    border-top: 1px solid rgba(0,0,0,0); /* Hide border when active */
  }

  .Header .links a span {
    display: inline;
    display: block;
    margin: 1px 0 0;
    color: #999;
    font-size: 14px;
    font-weight: 400;
  }

  /*** Login Button ***/

  .Header .login,
  .Header .login:hover {
    width: calc(100% - 60px);
    color: #fff;
    background: #3F9AF7;
    border: 0;
    border-radius: 3px;
    margin: 0 auto 40px;
    padding: 15px 0;
    display: block;
    font-size: 15px;
    height: auto;
    line-height: normal;
  }
}

.Footer {
  padding: 70px 25px 30px;
  position: relative;
  background: #2c3e50;
  font-size: 14px;
  color: #fff;
}

@supports (padding: env(safe-area-inset-top)) {
  .Footer {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 30px);
  }
}

.Footer .content {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.Footer .content > * {
  vertical-align: top;
}

/*** About ***/

.Footer .about {
  width: 25%;
  display: inline-block;
  text-align: center;
}

.Footer .logo {
  display: inline-block;
  height: 30px;
  text-decoration: inherit;
  color: inherit;
  margin: 0 0 20px;
}

.Footer .icon {
  height: 30px;
  width: 30px;
  vertical-align: middle;
  fill: #3F9AF7;
}

.Footer .icon .inner {
  fill: #fff;
}

.Footer .title {
  font-weight: 600;
  font-size: 22px;
  margin-left: 10px;
  vertical-align: middle;
}

.Footer .apps img {
  width: 100%;
  display: block;
  max-width: 150px;
  margin: 10px auto 0;
}

/*** Links ***/

.Footer .links {
  width: calc(75% - 100px);
  display: inline-block;
  padding-left: 100px;
  text-align: left;
}

.Footer .column {
  display: inline-block;
  padding: 0;
  width: calc(100%/3);
  vertical-align: top;
}

.Footer .column h1 {
  font-size: 16px;
  color: #fff;
  margin: 0 0 15px;
  font-weight: 600;
}

.Footer .column a {
  color: #bbb;
  text-decoration: none;
  transition: color .2s;
  padding: 6px 0;
  display: block;
}

.Footer .column a:hover {
  color: #fff;
}

/*** Copyright ***/

.copyright {
  margin: 100px 0 0;
  padding: 30px 0 0;
  color: #bbb;
  border-top: 2px solid rgba(255, 255, 255, .1);
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  .Footer {
    padding: 50px 15px 20px;
  }

  @supports (padding: env(safe-area-inset-top)) {
    .Footer {
      padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  }

  /*** About ***/

  .Footer .about {
    width: auto;
    display: block;
  }

  .Footer .logo {
    display: none;
  }

  .Footer .apps img {
    display: inline-block;
    width: calc(50% - 10px);
    margin: 0 5px 5px;
  }

  /*** Links ***/

  .Footer .links {
    width: auto;
    display: block;
    padding: 40px 0 0;
    text-align: center;
  }
}

.Demo {
  background: #fff;
  padding: 64px 5px 5px;
  margin-left: auto;
  max-width: 350px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 7px 20px -5px rgb(0 0 0 / 50%);
}

/*** Instructions ***/

.Demo .instructions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
  font-size: 14px;
}

/*** Header ***/

.Demo .header {
  padding: 9px;
  height: 26px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 10px 10px 0 0;
  text-align: center;
  background: rgb(230, 126, 34);
  z-index: 50;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

/* Buttons */

.Demo .header .buttons {
  display: inline-block;
  position: absolute;
  top: 9px;
}

.Demo .header .buttons.left {
  left: 8px;
}

.Demo .header .buttons.right {
  right: 8px;
}

.Demo .header .icon {
  fill: #fff;
  height: 22px;
  width: 22px;
  vertical-align: middle;
  padding: 0 7px;
  display: inline-block;
  position: relative;
}

/* Title */

.Demo .header .title {
  display: inline-block;
  margin: auto;
  font-size: 0;
  font-weight: 600;
  height: 26px;
  vertical-align: middle;
}

.Demo .header .title span {
  vertical-align: middle;
  font-size: 18px;
}

.Demo .header .title .icon {
  padding-right: 5px;
}

.Demo .icon svg {
  height: 100%;
  overflow: hidden;
}

/*** Cards ***/

.Demo .Card {
  display: inline-block;
  position: relative;
  vertical-align: top;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 4%);
  border-radius: 7px;
  margin: 0 10px 20px;
  font-size: 14px;
  padding: 15px 30px 15px 15px;
  width: calc(100% - 67px);
  background: #fff;
  color: #000;
}

/* With Customer */
.Demo .Card.Customer {
  background: rgb(230, 242, 255);
}

/* On Hold */
.Demo .Card.Hold {
  background: rgb(238, 238, 238);
  margin-bottom: 15px;
}

/*** Menu ***/

.Demo .Card::before {
  content: "⋮";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(153, 153, 153);
  line-height: 1;
  padding: 0;
}

.Demo .Card .fauxMenu {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 9px;
}

/*** Fields ***/

.Demo .Card .field {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  margin: 0 1px 2px;
  width: calc(100% - 2px);
  position: relative;
}

.Demo .Card .field span {
  position: relative;
}

/* Name */

.Demo .Card .field.name {
  width: calc(66.66% - 2px);
  font-size: 22px;
}

/* Status */

.Demo .Card .field.status {
  width: calc(33.33% - 2px);
  text-align: right;
}

.Demo .Card .field.status span {
  background: green;
  border-radius: 10px;
  color: #fff;
  padding: 1px 7px 2px;
  position: relative;
}

.Demo .Card.Customer .field.status span {
  background: rgb(52, 152, 219);
}

.Demo .Card.Hold .field.status span {
  background: #777;
}

/* Wait Time */

.Demo .Card .field.wait {
  color: rgb(119, 119, 119);
}

/* Customer Name */

.Demo .Card .field.customer {
  color: rgb(119, 119, 119);
}

/*** Tooltips ***/

.Demo .Tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.Demo .Tooltip.show {
  opacity: 1;
  visibility: visible;
  transition: visibility, opacity;
  transition-duration: .5s;
  transition-timing-function: ease-in;
}

/* Show Tooltips on Demo Hover */

.Demo:hover .Tooltip {
  opacity: 1;
  visibility: visible;
  transition: visibility .5s, opacity .5s;
}

.Demo:hover .Tooltip .indicator:hover ~ .tip {
  opacity: 1;
  visibility: visible;
  transition: visibility .3s, opacity .3s;
}

.Demo:hover .Tooltip .tip {
  opacity: 0;
  visibility: hidden;
}

/* Tooltip Content */

.Demo .Tooltip .tip {
  position: absolute;
  top: calc(100% + 15px);
  left: -1px;
  background: #333;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  width: 150px;
  z-index: 100;
}

.Demo .Tooltip.right .tip {
  left: -137px;
}

.Demo .Tooltip.bottom .tip {
  top: auto;
  top: initial;
  bottom: calc(100% + 15px);
}

.Demo .Tooltip .tip::before {
  content: "";
  position: absolute;
  top: -7px;
  left: calc(50% - 7px);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #333;
}

.Demo .Tooltip.left .tip::before {
  left: 7px;
}

.Demo .Tooltip.right .tip::before {
  left: auto;
  left: initial;
  right: 7px;
}

.Demo .Tooltip.bottom .tip::before {
  top: auto;
  top: initial;
  bottom: -7px;
  border-top: 7px solid #333;
  border-bottom: none;
}

/* Tooltip Indicators */

.Demo .Tooltip .indicator {
  content: "";
  position: absolute;
  top: -6px;
  left: -4px;
  width: 30px;
  height: 30px;
  background: rgba(0,0,0,.1);
  border: 2px solid #f2f2f2;
  border-top-color: rgb(41,128,185);
  border-bottom-color: rgb(41,128,185);
  box-shadow: 0 0 0 1px rgb(41,128,185),
    0 0 0 1px rgb(41,128,185) inset;
  border-radius: 50%;
  -webkit-animation: animateIndicators 7s linear infinite;
          animation: animateIndicators 7s linear infinite;
}

@-webkit-keyframes animateIndicators {
  to { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}

@keyframes animateIndicators {
  to { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}

/* Position Adjustments */

.Demo .header .icon .Tooltip {
  left: 5px;
}

.Background {
  width: 2000px;
  height: 1500px;
  position: absolute;
  left: 40%;
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
          transform: rotate(-20deg);
  z-index: -1;
}

.Background circle {
  fill: #3F9AF7;
  opacity: .15;
}

.Home .Hero .content {
  margin: 150px auto 200px;
}

.Home .content_left {
  display: inline-block;
  vertical-align: top;
  max-width: 660px;
}

.Home .content_right {
  display: inline-block;
  vertical-align: top;
  padding-left: 25px;
  float: right;
  width: calc(100% - 685px);
  width: calc(100% - 685px - env(safe-area-inset-left) - env(safe-area-inset-right));
}

/*** Hero Form ***/

.Home .Hero .form {
  padding: 44px 0;
  display: inline-block;
  position: relative;
}

/* Button to Show Form */

.Home .Hero .form .buttons {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: none;
  margin-top: 10px;
}

.Home .Hero .demo_button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 2px solid rgb(230, 126, 34);
  background: rgb(230, 126, 34);
  border-radius: 5px;
  font-family: inherit;
  font-weight: 600;
  color: #fff;
  padding: 8px 15px;
  line-height: normal;
  font-size: 16px;
  height: 50px;
  min-width: 150px;
  cursor: pointer;
  transition: color .2s,
    background .2s;
}

.Home .Hero .demo_button:hover {
  background: #fff;
  color: rgb(230, 126, 34);
}

.Home .Hero button,
.Home .Hero input,
.Home .Hero .form .status {
  box-shadow: 0 7px 20px -5px rgb(0 0 0 / 40%);
}

.Home .Hero .form.show_button .buttons {
  display: inline-block;
}

.Home .Hero .form.show_button form {
  visibility: hidden;
}

.Home .Hero .form .buttons div {
  display: inline-block;
  margin-left: 50px;
  transition: color .2s;
  text-shadow: 0 3px 10px rgb(0 0 0 / 30%);
}

.Home .Hero .form .buttons div a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.Home .Hero .form .buttons div b {
  display: inline-block;
  text-align: right;
  width: 22px;
  transition: width .2s;
}

.Home .Hero .form .buttons div:hover {
  color: #ccc;
}

.Home .Hero .form .buttons div:hover b {
  width: 27px;
}

/* Form */

.Home .Hero .form form {
  position: relative;
  max-width: 400px;
}

.Home .Hero form label {
  display: none;
}

.noPlaceholders .Home .Hero form label {
  display: inline;
  display: initial;
}

.noPlaceholders .Home .Hero form input.name {
  width: calc(100% - 30px) !important;
  margin-left: 0 !important;
}

.Home .Hero form input,
.Home .Hero form button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  font-family: inherit;
  padding: 0 15px;
  margin: 0;
  line-height: normal;
  font-size: 14px;
  height: 35px;
}

.Home .Hero form input {
  border-radius: 5px;
  background: #fff;
  width: calc(100% - 30px);
  margin-bottom: 20px;
}

.Home .Hero form input.name {
  width: calc(50% - 30px - 10px);
}

.Home .Hero form input.name:nth-of-type(2){
  margin-left: 20px;
}

.Home .Hero form button {
  border-radius: 5px;
  background: rgb(230, 126, 34);
  border: 2px solid rgb(230, 126, 34);
  min-width: 100%;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background .2s,
    color .2s;
  display: block;
  margin: auto;
}

.Home .Hero form button:hover {
  background: #fff;
  color: rgb(230, 126, 34);
}

/* Form Status */

.Home .Hero .form .status {
  position: absolute;
  z-index: 1;
  width: calc(100% - 60px);
  display: none;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  padding: 20px 30px 25px;
  color: #000;
}

.Home .Hero .form .status h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
}

.Home .Hero .form .status a {
  color: inherit;
  cursor: pointer;
}

.Home .Hero .form.show_success .success,
.Home .Hero .form.show_error .error {
  display: block;
}

.Home .Hero .form.show_success form,
.Home .Hero .form.show_error form {
  visibility: hidden;
}

/*** Responsive Widths ***/
/* Medium */
@media only screen and (max-width: 1024px){
  .Home .Hero .content {
    margin: 100px auto 150px;
  }

  .Home .content_left {
    max-width: 475px;
  }

  .Home .Hero .form form {
    max-width: none;
  }

  .Home .content_right {
    float: none;
    width: calc(100% - 500px);
    width: calc(100% - 500px - env(safe-area-inset-left) - env(safe-area-inset-right));
  }

  .Demo {
    margin: auto;
  }
}

/* Move Demo Below Hero Content */
@media only screen and (max-width: 850px){
  .Home .content_left {
    max-width: auto;
    display: block;
    margin: auto;
  }

  .Home .Hero .form .buttons {
    text-align: center;
  }

  .Home .Hero .form .buttons div {
    display: block;
    margin: 30px 0 0;
  }

  .Home .Hero .form .buttons div b,
  .Home .Hero .form .buttons div:hover b {
    width: auto;
    padding: 0 0 0 5px;
  }

  .Home .content_right {
    display: block;
    float: none;
    padding: 0;
    width: 100%;
  }

  .Demo {
    margin: auto;
  }

  /*** Background ***/
  .Background {
    left: 30%;
    bottom: 15%;
  }
}

/* Mobile */
@media only screen and (max-width: 720px){
  .Home .Hero .content {
    margin: 50px auto 150px;
  }
}

.Home .Overview {
  background: #fafafa;
}

.Home .Start {
  background: #fff;
}

.Start .content {
  max-width: 800px;
  display: table;
}

.Home .Start .content {
  padding-bottom: 150px;
}

.Start .half {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

.Start h1,
.Start h2 {
  margin: 0 !important;
  padding: 0 15px !important;
}

.Start .button {
  display: block;
  width: 150px;
  margin: 0 20px 0 0;
  padding: 0;
  cursor: pointer;
  background: #3F9AF7;
  color: #fff;
  border: 2px solid #3F9AF7;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  line-height: 50px;
  text-align: center;
  transition: color .2s,
    background .2s;
}

.Start .button:hover {
  background: #fff;
  color: #3F9AF7;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .Start .top {
    display: table-header-group;
  }

  .Start .bottom {
    display: table-footer-group;
  }

  .Start .half {
    text-align: center;
  }

  .Start .button {
    margin: 30px auto 0;
  }
}

.Solutions {
  background: #fafafa;
}

.Solutions .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .Solutions .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

.Solutions > .content > h1,
.Solutions > .content > h2 {
  padding: 0 15px;
}

.Solutions > .content > h2 {
  margin: 10px 0 70px;
}

.Solutions .Start .content {
  padding-bottom: 150px;
}

/* Solution Item Styles */

.SolutionItems .item h2 {
  border-bottom: 3px solid;
  margin-bottom: 15px;
}


.SolutionItems #finance_dispatch h2 { border-color: rgb(225, 112, 85); }
.SolutionItems #finance_dispatch:hover .learn { color: rgb(225, 112, 85); }

.SolutionItems #service_drive_dispatch h2 { border-color: rgb(39, 174, 96); }
.SolutionItems #service_drive_dispatch:hover .learn { color: rgb(39, 174, 96); }

.SolutionItems #up_system h2 { border-color: rgb(230, 126, 34); }
.SolutionItems #up_system:hover .learn { color: rgb(230, 126, 34); }

.SolutionItems #valet_dispatch h2 { border-color: rgb(40, 128, 186); }
.SolutionItems #valet_dispatch:hover .learn { color: rgb(40, 128, 186); }


.SolutionItems #wash_rack h2 { border-color: rgb(199, 131, 227); }
.SolutionItems #wash_rack:hover .learn { color: rgb(199, 131, 227); }


/* Linked Items */

.SolutionItems a.item {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.SolutionItems a.item .learn {
  color: #777;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  line-height: 27px;
  position: relative;
  padding: 3px 3px 0 10px;
  transition: color .2s;
}

.SolutionItems a.item .learn::after {
  content: "";
  height: 4px;
  width: 4px;
  border-top: 1px solid;
  border-right: 1px solid;
  display: inline-block;
  -webkit-transform: rotate(45deg)
    translateY(-50%);
      -ms-transform: rotate(45deg)
    translateY(-50%);
          transform: rotate(45deg)
    translateY(-50%);
}

.SolutionItems .item .learn:hover { text-decoration: underline; }

/* Bullet Points */

.SolutionItems .item p {
  padding: 0 0 5px 10px;
  margin: 0;
  position: relative;
  line-height: normal;
}

.SolutionItems .item p::before {
  content: "•";
  position: absolute;
  left: 0;
}

/* Spacer Items */

.SolutionItems .item.link,
.SolutionItems .item.spacer {
  min-height: 166px;
}

.SolutionItems .item.link h2 {
  border: none;
  margin: 0;
}

.SolutionItems .item.spacer {
  visibility: hidden;
}

.Home .Logos {
  background: #fff;
}

.Home .Logos .logo {
  display: inline-block;
  vertical-align: middle;
  padding: 15px 50px;
  width: calc(100%/3 - 100px);
}

.Home .Logos .logo {
  min-height: 100px;
  position: relative;
}

.Home .Logos .logo img {
  width: calc(100% - 100px);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*** Responsive Widths ***/
/* Mobile Portrait */
@media only screen and (max-width: 550px){
  .Home .Logos .logo {
    display: block;
    vertical-align: middle;
    padding: 30px 50px;
    width: calc(100% - 100px);
  }
}

.Home {
  background: #3F9AF7;
}

.Home .content {
  margin: 0 auto;
  max-width: 1100px;
  padding: 150px 10px 200px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .Home .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

.Home h1 {
  margin: 0 auto 50px;
  padding: 0 15px;
}

.Home h2 {
  margin: -40px auto 40px;
  padding: 0 15px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .Home .content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.Features {
  background: #fafafa;
}

.Features .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .Features .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

.Features > .content > h1,
.Features > .content > h2 {
  padding: 0 15px;
}

.Features > .content > h2 {
  margin: 10px 0 70px;
}

.Features .Start .content {
  padding-bottom: 150px;
}

.ServiceDriveDispatch .Features {
  background: #fafafa;
}

.ServiceDriveDispatch {
  background: rgb(39, 174, 96);
}

.ServiceDriveDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .ServiceDriveDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.ServiceDriveDispatch .Hero {
  background: linear-gradient(rgb(39, 174, 96), rgb(28, 125, 68));
}

.ServiceDriveDispatch .Hero .content {
  margin: 10px auto 50px;
}

.ServiceDriveDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.ServiceDriveDispatch .Background {
  left: 30%;
}

.ServiceDriveDispatch .Background circle {
  fill: rgb(39, 174, 96);
}

/* Start */

.ServiceDriveDispatch .Start {
  background: #fafafa;
}

.ServiceDriveDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .ServiceDriveDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}

@media print {

  /* Specify page size when printing */
  @page {
    size: letter portrait;
    margin: .25in;
    /* width: (8.5in - .25in - .25in) * 96px/in) = 768px */
    /* height: (11in - .25in - .25in) * 96px/in) = 1008px */
  }

  /* Make .App full height */
  .App {
    min-height: 100vh;
  }

  /* Tweak header */
  .DetailsPage ~ .Header {
    left: auto;
    left: initial;
    font-weight: 600;
  }

  .DetailsPage ~ .Header .PrintViewLinks {
    display: block;
  }

  .DetailsPage ~ .Header .menu {
    display: none;
  }

  .DetailsPage {
    padding-top: 0 !important;
    background: none !important;
  }

  .DetailsPage .Hero .content {
    margin: 20px 0 !important;
  }

  .DetailsPage .Hero .content h3 {
    font-weight: 600;
  }

  /* Hide chat widget */
  div#hubspot-messages-iframe-container {
    display: none !important;
  }

  /* Adjust background colors */
  .DetailsPage .Features {
    background: #fff !important;
  }

  .DetailsPage .Features .content {
    padding: 30px 0 !important;
  }

  .DetailsPage .Features .content > h1,
  .DetailsPage .Features .content > h2 {
    display: none;
  }

  .DetailsPage .Features .item {
    outline: 1px solid #aaa;
    box-shadow: none;
    padding: 23.04px;
    font-size: 12.29px;
    margin: 11.52px;
    width: calc(100%/3 - 46.08px - 23.04px);
  }

  .DetailsPage .Features .item h2 {
    font-size: 15.36px;
    padding: 0 0 7.68px;
  }

  .DetailsPage .Features a.item {
    min-height: 62.21px;
  }

  .DetailsPage .Features .item a {
    color: inherit;
  }

  /* Show URL item */
  .DetailsPage .Features a.item h2::after {
    /* display: none; */
    content: " at https://lotsuite.com";
    display: inline;
    position: relative;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    border: none;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    right: auto;
    right: initial;
    height: auto;
    height: initial;
    width: auto;
    width: initial;
  }

  /* Hide `Start` */
  .DetailsPage .Start {
    display: none;
  }

  /* Tweak Footer */
  .DetailsPage ~ .Footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 0;
    background: none;
  }

  .DetailsPage ~ .Footer .about,
  .DetailsPage ~ .Footer .links {
    display: none;
  }

  .DetailsPage ~ .Footer .copyright {
    margin: 0;
    color: #777;
  }

}

.FinanceDispatch .Features {
  background: #fafafa;
}

.FinanceDispatch {
  background: rgb(225, 112, 85);
}

.FinanceDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .FinanceDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.FinanceDispatch .Hero {
  background: linear-gradient(rgb(225, 112, 85), rgb(171, 77, 55));
}

.FinanceDispatch .Hero .content {
  margin: 10px auto 50px;
}

.FinanceDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.FinanceDispatch .Background {
  left: 30%;
}

.FinanceDispatch .Background circle {
  fill: rgb(225, 112, 85);
}

/* Start */

.FinanceDispatch .Start {
  background: #fafafa;
}

.FinanceDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .FinanceDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}

.UpSystem .Features {
  background: #fafafa;
}

.UpSystem {
  background: rgb(230, 126, 34);
}

.UpSystem .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .UpSystem .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.UpSystem .Hero {
  background: linear-gradient(rgb(230, 126, 34), rgb(173, 91, 17));
}

.UpSystem .Hero .content {
  margin: 10px auto 50px;
}

.UpSystem .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.UpSystem .Background {
  left: 30%;
}

.UpSystem .Background circle {
  fill: rgb(230, 126, 34);
}

/* Start */

.UpSystem .Start {
  background: #fafafa;
}

.UpSystem .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .UpSystem .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}

.ValetDispatch .Features {
  background: #fafafa;
}

.ValetDispatch {
  background: rgb(40, 128, 186);
}

.ValetDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .ValetDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.ValetDispatch .Hero {
  background: linear-gradient(rgb(40, 128, 186), rgb(21, 91, 138));
}

.ValetDispatch .Hero .content {
  margin: 10px auto 50px;
}

.ValetDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.ValetDispatch .Background {
  left: 30%;
}

.ValetDispatch .Background circle {
  fill: rgb(40, 128, 186);
}

/* Start */

.ValetDispatch .Start {
  background: #fafafa;
}

.ValetDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .ValetDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}

.WashRack .Features {
  background: #fafafa;
}

.WashRack {
  background: rgb(199, 131, 227);
}

.WashRack .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .WashRack .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.WashRack .Hero {
  background: linear-gradient(rgb(199, 131, 227), rgb(160, 101, 186));
}

.WashRack .Hero .content {
  margin: 10px auto 50px;
}

.WashRack .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.WashRack .Background {
  left: 30%;
}

.WashRack .Background circle {
  fill: rgb(199, 131, 227);
}

/* Start */

.WashRack .Start {
  background: #fafafa;
}

.WashRack .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .WashRack .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}

.Contact {
  text-align: center;
  background: #fafafa;
}

.Contact .container {
  text-align: left;
  margin: 70px auto 150px;
  padding: 50px 70px 90px;
  background: #fff;
  display: inline-block;
  box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
  border-radius: 3px;
}

.Contact h1 {
  margin: 0;
  padding: 0;
}

.Contact form {
  width: 450px;
}

.Contact .status {
  background: rgba(46, 204, 113, .2);
  border-radius: 5px;
  padding: 15px;
  margin: 70px 0 50px;
  width: calc(450px - 30px);
  text-align: center;
}

.Contact .status.error {
  background: rgba(231, 76, 60, .2);
}

.Contact .status.error u,
.Contact .status.error a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
}

.Contact label {
  display: none;
  margin: 30px 0 -30px;
  font-size: 14px;
  padding: 0 0 3px 5px;
}

.noPlaceholders .Contact label {
  display: block;
}

.Contact input,
.Contact textarea {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: inherit;
  padding: 8px 15px;
  font-size: 14px;
  height: calc(35px - 16px);  /* Height - Padding */
  width: calc(100% - 30px - 2px);  /* Width - Padding - Border */
  margin: 30px 0 0;
  display: block;
}

.Contact input[name=firstname],
.Contact input[name=lastname] {
  width: calc(50% - 30px - 2px - 10px);  /* Width - Padding - Border - Margin */
  display: inline-block;
}

.noPlaceholders .Contact input[name=firstname],
.noPlaceholders .Contact input[name=lastname] {
  width: calc(100% - 30px - 2px);
  display: block;
  float: none;
}

.Contact input[name=lastname] {
  float: right;
}

.Contact textarea {
  height: 105px;
}

.Contact input[type=submit] {
  width: 150px;
  margin: 50px auto 0;
  cursor: pointer;
  background: #3F9AF7;
  color: #fff;
  border: 2px solid #3F9AF7;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  transition: color .2s,
    background .2s;
}

.Contact input[type=submit]:hover {
  color: #3F9AF7;
  background: none;
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  .Contact .container {
    padding: 40px 30px 70px;
    width: calc(100% - 60px - 30px);  /* Width - Padding - Margin */
  }

  .Contact form {
    width: 100%;
  }

  .Contact .status {
    width: calc(100% - 30px);
  }

  .Contact input,
  .Contact textarea {
    font-size: 16px;  /* Prevent zoom in input focus on iOS */
  }

  .Contact input[name=firstname],
  .Contact input[name=lastname] {
    width: calc(100% - 30px - 2px);
    display: block;
    float: none;
  }
}

/* Header */

.App > div:first-child {
  padding-top: 70px;  /* Space for Header */
}

/* Browser Workarounds */

a::-moz-focus-inner {
  border: 0;  /* Remove border on links in Firefox */
}

/* Headings */

h1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 35px;
  color: #2c3e50;
}

h2 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  color: #2c3e50;
}

/* Sections */

section {
  position: relative;
}

section .convex,
section .concave {
  position: absolute;
  height: 25px;
  width: 100%;
  z-index: 1;
  right: 0;
}

section .convex.top,
section .concave.top {
  top: -25px;
}

section .convex.bottom ,
section .concave.bottom {
  bottom: -25px;
}

section .convex.top,
section .concave.bottom {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

section .convex path,
section .concave path {
  fill: #fafafa;
}

/* Hero Section */

.Hero {
  background: linear-gradient(#3F9AF7, #0865C4 500px);
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.Hero .content {
  padding: 0 25px;
  z-index: 2;
  text-align: left;
}

@supports (padding: env(safe-area-inset-top)) {
  .Hero .content {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
  }
}

.Hero .h_wrapper {
  display: inline-block;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  font-family: Raleway, "Open Sans", "Helvetica Neue", sans-serif;;
}

.Hero h1 {
  margin: 0 auto 30px;
  padding: 0;
  font-weight: 800;
  font-size: 70px;
  color: inherit;
  line-height: 1;
}

.Hero h2 {
  margin: 50px 0 20px;
  padding: 0;
  font-weight: 600;
  color: inherit;
  text-transform: uppercase;
  line-height: 1;
  font-size: 18px;
}

.Hero h3 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  max-width: 400px;
}
.Hero h3 span {
  font-weight: 600;
}

/* Items */

*.item {
  text-align: left;
  background: #fff;
  display: inline-block;
  box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
  border-radius: 3px;
  padding: 30px;
  margin: 15px;
  width: calc(100%/3 - 60px - 30px);
  vertical-align: top;
  position: relative;
}

.item h2 {
  margin: 0;
  padding: 0 0 10px;
  font-weight: 600;
  color: #2c3e50;
  font-size: 20px;
}

.item.new h2::after {
  content: "NEW";
  margin-left: 7px;
  padding: 1px 5px;
  background: #1abc9c;
  color: #fff;
  border-radius: 2px;
  font-size: 60%;
  vertical-align: middle;
}

/* Items that are Links */

a.item {
  min-height: 81px;
  text-decoration: none;
}

a.item .center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0;
  padding: 0;
}

a.item .center h2 {
  padding-bottom: 0 !important;
  padding-right: 15px;
  display: inline-block;
  position: relative;
}

a.item .center h2::after {
  content: "";
  height: 7px;
  width: 7px;
  border-top: 2px solid;
  border-right: 2px solid;
  display: inline-block;
  -webkit-transform: rotate(45deg)
    translateY(-50%);
      -ms-transform: rotate(45deg)
    translateY(-50%);
          transform: rotate(45deg)
    translateY(-50%);
  position: absolute;
  right: 7px;
  top: 50%;
  transition: right .2s;
}

a.item:hover .center h2::after {
  right: 0;
}

/* Item Links */

.item a {
  color: #3F9AF7;
  text-decoration: none;
  transition: border .2s;
  border-bottom: 1px solid;
  border-color: rgba(255,255,255,0);
}

.item a:hover {
  border-color: #3F9AF7;
}

/* Show More Items */

.showMoreItems {
  display: none;
}

/*** Responsive Widths ***/
/* Medium */
@media only screen and (max-width: 1024px){
  /* Waves */

  section .convex,
  section .concave {
    height: 10px;
  }

  section .convex.top,
  section .concave.top {
    top: -10px;
  }

  section .convex.bottom ,
  section .concave.bottom {
    bottom: -10px;
  }

  /* Hero Section */

  .Hero .content {
    margin: 100px auto 200px;
  }

  .Hero h1 {
    font-size: 50px;
  }
}

/* Mobile */
@media only screen and (max-width: 720px){
  .item {
    width: calc(50% - 60px - 30px);
  }
}

/* Mobile Portrait */
@media only screen and (max-width: 550px){
  /* Headings */

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
  }

  /* Items */

  .item {
    display: block;
    margin: 30px 15px;
    width: calc(100% - 60px - 30px);
  }

  /* Show More Items */

  .showMoreItems {
    display: block;
    margin: 30px 0;
    position: relative;
  }

  .showMoreItems .button {
    position: absolute;
    top: 35px;
    z-index: 10;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #3F9AF7;
    padding: 7px 15px;
    border-radius: 10px;
    cursor: pointer;
  }

  .showMoreItems .fakeItem {
    background: #fff;
    box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
    border-radius: 3px 3px 0 0;
    padding: 30px;
    margin: 30px 15px 0;
    width: calc(100% - 60px - 30px);
    position: relative;
  }

  .showMoreItems .fakeItem::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    bottom: -30px;
    background: linear-gradient(rgba(250,250,250,0) 30px, #fafafa 60px);
  }

  .showMoreItems.show,
  .showMoreItems ~ .item {
    display: none;
  }

  .showMoreItems.show ~ .item {
    display: inherit;
  }

  /* Spacer Items */

  .SolutionItems .item.link,
  .SolutionItems .item.spacer {
    display: none;
  }
}

