.Home .Start {
  background: #fff;
}

.Start .content {
  max-width: 800px;
  display: table;
}

.Home .Start .content {
  padding-bottom: 150px;
}

.Start .half {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

.Start h1,
.Start h2 {
  margin: 0 !important;
  padding: 0 15px !important;
}

.Start .button {
  display: block;
  width: 150px;
  margin: 0 20px 0 0;
  padding: 0;
  cursor: pointer;
  background: #3F9AF7;
  color: #fff;
  border: 2px solid #3F9AF7;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  line-height: 50px;
  text-align: center;
  transition: color .2s,
    background .2s;
}

.Start .button:hover {
  background: #fff;
  color: #3F9AF7;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .Start .top {
    display: table-header-group;
  }

  .Start .bottom {
    display: table-footer-group;
  }

  .Start .half {
    text-align: center;
  }

  .Start .button {
    margin: 30px auto 0;
  }
}
