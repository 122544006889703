@media print {

  /* Specify page size when printing */
  @page {
    size: letter portrait;
    margin: .25in;
    /* width: (8.5in - .25in - .25in) * 96px/in) = 768px */
    /* height: (11in - .25in - .25in) * 96px/in) = 1008px */
  }

  /* Make .App full height */
  .App {
    min-height: 100vh;
  }

  /* Tweak header */
  .DetailsPage ~ .Header {
    left: initial;
    font-weight: 600;
  }

  .DetailsPage ~ .Header .PrintViewLinks {
    display: block;
  }

  .DetailsPage ~ .Header .menu {
    display: none;
  }

  .DetailsPage {
    padding-top: 0 !important;
    background: none !important;
  }

  .DetailsPage .Hero .content {
    margin: 20px 0 !important;
  }

  .DetailsPage .Hero .content h3 {
    font-weight: 600;
  }

  /* Hide chat widget */
  div#hubspot-messages-iframe-container {
    display: none !important;
  }

  /* Adjust background colors */
  .DetailsPage .Features {
    background: #fff !important;
  }

  .DetailsPage .Features .content {
    padding: 30px 0 !important;
  }

  .DetailsPage .Features .content > h1,
  .DetailsPage .Features .content > h2 {
    display: none;
  }

  .DetailsPage .Features .item {
    outline: 1px solid #aaa;
    box-shadow: none;
    padding: 23.04px;
    font-size: 12.29px;
    margin: 11.52px;
    width: calc(100%/3 - 46.08px - 23.04px);
  }

  .DetailsPage .Features .item h2 {
    font-size: 15.36px;
    padding: 0 0 7.68px;
  }

  .DetailsPage .Features a.item {
    min-height: 62.21px;
  }

  .DetailsPage .Features .item a {
    color: inherit;
  }

  /* Show URL item */
  .DetailsPage .Features a.item h2::after {
    /* display: none; */
    content: " at https://lotsuite.com";
    display: inline;
    position: relative;
    transform: none;
    border: none;
    top: initial;
    left: initial;
    right: initial;
    height: initial;
    width: initial;
  }

  /* Hide `Start` */
  .DetailsPage .Start {
    display: none;
  }

  /* Tweak Footer */
  .DetailsPage ~ .Footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 0;
    background: none;
  }

  .DetailsPage ~ .Footer .about,
  .DetailsPage ~ .Footer .links {
    display: none;
  }

  .DetailsPage ~ .Footer .copyright {
    margin: 0;
    color: #777;
  }

}
