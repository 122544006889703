.Header {
  padding: 20px 25px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  fill: #fff;
  font-size: 14px;
  z-index: 100;
}

@supports (padding: env(safe-area-inset-top)) {
  .Header {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
  }
}

.darkHeader + .Header {
  color: #2c3e50;
  fill: #2c3e50;
}

.Header .content {
  height: 30px;
  max-width: 1200px;
  margin: auto;
  position: relative;
}

/*** Logo ***/

.Header .logo {
  display: inline-block;
  height: 30px;
  text-decoration: inherit;
  color: inherit;
}

.Header .icon {
  height: 30px;
  width: 30px;
  vertical-align: middle;
}

.Header .icon .outer,
.darkHeader + .Header .icon .inner {
  fill: #fff;
}

.Header .icon .inner {
  fill: none;
}

.darkHeader + .Header .icon .outer {
  fill: #3F9AF7;
}

.Header .title {
  font-weight: 600;
  font-size: 22px;
  margin-left: 10px;
  vertical-align: middle;
}

/*** Print View Links ***/

.PrintViewLinks {
  display: none;
  margin: 7px 0 0;
}

.PrintViewLinks a {
  display: block;
  text-align: right;
  margin: 1px 0 0;
  font-size: 12.29px;
  color: inherit;
  text-decoration: none;
}

.PrintViewLinks a:last-child::before {
  content: "hello";
}

/*** Menu ***/

.Header .open,
.Header .close {
  height: 30px;
  width: 30px;
  fill: inherit;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.Header .menu {
  display: inline-block;
  float: right;
  height: 30px;
}

/*** Links ***/

.Header .links {
  display: inline-block;
  height: 100%;
}

.Header .links a {
  color: inherit;
  text-decoration: inherit;
  line-height: 30px;
  margin-left: 30px;
  transition: color .2s;
  border-bottom: 1px solid rgba(255,255,255,0);
  transition: border-color .2s;
}

.Header .links a:hover,
.Header .links a.active,
.Header .links a.active {
  border-color: inherit;
}

.Header .links a span {
  display: none;
}

/*** Login Button ***/

.Header .login {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 15px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
}

.darkHeader + .Header .login {
  border-color: #2c3e50;
}

.Header .login:hover {
  color: #3F9AF7;
  background: #fff;
}

.darkHeader + .Header .login:hover {
  color: #fff;
  background: #2c3e50;
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  /*** Menu ***/

  .Header .open {
    display: initial;
  }

  .Header .menu {
    display: none;
    float: none;
    height: auto;
    background: #fff;
    color: #000;
    position: absolute;
    top: -10px;
    left: -15px;
    right: -15px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1),
      0 5px 25px 0 rgba(0,0,0,.5),
      0 2px 2px 0 rgba(0,0,0,.1);
  }

  .Header .menu.show {
    display: block;
  }

  .Header .menu .close {
    fill: #aaa;
    top: 10px;
    right: 15px;
    display: initial;
  }

  /*** Links ***/

  .Header .links {
    display: block;
    height: auto;
    padding: 40px 30px 30px;
    font-size: 16px;
  }

  .Header .links a,
  .Header .links a:hover,
  .Header .links a.active,
  .Header .links a.active {
    display: block;
    margin: 0;
    line-height: normal;
    font-weight: 600;
    padding: 15px;
    border-top: 1px solid rgba(0,0,0,0.05);
    border-bottom: 0;
    transition: border-color 0s;
  }

  .Header .links a.active {
    background: rgba(63, 154, 247, .1);
    border-radius: 3px;
  }

  .Header .links a:first-of-type,
  .Header .links a.active,
  .Header .links a.active + a {
    border-top: 1px solid rgba(0,0,0,0); /* Hide border when active */
  }

  .Header .links a span {
    display: inline;
    display: block;
    margin: 1px 0 0;
    color: #999;
    font-size: 14px;
    font-weight: 400;
  }

  /*** Login Button ***/

  .Header .login,
  .Header .login:hover {
    width: calc(100% - 60px);
    color: #fff;
    background: #3F9AF7;
    border: 0;
    border-radius: 3px;
    margin: 0 auto 40px;
    padding: 15px 0;
    display: block;
    font-size: 15px;
    height: auto;
    line-height: normal;
  }
}
