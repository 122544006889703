.Footer {
  padding: 70px 25px 30px;
  position: relative;
  background: #2c3e50;
  font-size: 14px;
  color: #fff;
}

@supports (padding: env(safe-area-inset-top)) {
  .Footer {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 30px);
  }
}

.Footer .content {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.Footer .content > * {
  vertical-align: top;
}

/*** About ***/

.Footer .about {
  width: 25%;
  display: inline-block;
  text-align: center;
}

.Footer .logo {
  display: inline-block;
  height: 30px;
  text-decoration: inherit;
  color: inherit;
  margin: 0 0 20px;
}

.Footer .icon {
  height: 30px;
  width: 30px;
  vertical-align: middle;
  fill: #3F9AF7;
}

.Footer .icon .inner {
  fill: #fff;
}

.Footer .title {
  font-weight: 600;
  font-size: 22px;
  margin-left: 10px;
  vertical-align: middle;
}

.Footer .apps img {
  width: 100%;
  display: block;
  max-width: 150px;
  margin: 10px auto 0;
}

/*** Links ***/

.Footer .links {
  width: calc(75% - 100px);
  display: inline-block;
  padding-left: 100px;
  text-align: left;
}

.Footer .column {
  display: inline-block;
  padding: 0;
  width: calc(100%/3);
  vertical-align: top;
}

.Footer .column h1 {
  font-size: 16px;
  color: #fff;
  margin: 0 0 15px;
  font-weight: 600;
}

.Footer .column a {
  color: #bbb;
  text-decoration: none;
  transition: color .2s;
  padding: 6px 0;
  display: block;
}

.Footer .column a:hover {
  color: #fff;
}

/*** Copyright ***/

.copyright {
  margin: 100px 0 0;
  padding: 30px 0 0;
  color: #bbb;
  border-top: 2px solid rgba(255, 255, 255, .1);
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  .Footer {
    padding: 50px 15px 20px;
  }

  @supports (padding: env(safe-area-inset-top)) {
    .Footer {
      padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  }

  /*** About ***/

  .Footer .about {
    width: auto;
    display: block;
  }

  .Footer .logo {
    display: none;
  }

  .Footer .apps img {
    display: inline-block;
    width: calc(50% - 10px);
    margin: 0 5px 5px;
  }

  /*** Links ***/

  .Footer .links {
    width: auto;
    display: block;
    padding: 40px 0 0;
    text-align: center;
  }
}
