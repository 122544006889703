.Home .Logos {
  background: #fff;
}

.Home .Logos .logo {
  display: inline-block;
  vertical-align: middle;
  padding: 15px 50px;
  width: calc(100%/3 - 100px);
}

.Home .Logos .logo {
  min-height: 100px;
  position: relative;
}

.Home .Logos .logo img {
  width: calc(100% - 100px);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*** Responsive Widths ***/
/* Mobile Portrait */
@media only screen and (max-width: 550px){
  .Home .Logos .logo {
    display: block;
    vertical-align: middle;
    padding: 30px 50px;
    width: calc(100% - 100px);
  }
}
