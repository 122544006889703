.WashRack {
  background: rgb(199, 131, 227);
}

.WashRack .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .WashRack .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.WashRack .Hero {
  background: linear-gradient(rgb(199, 131, 227), rgb(160, 101, 186));
}

.WashRack .Hero .content {
  margin: 10px auto 50px;
}

.WashRack .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.WashRack .Background {
  left: 30%;
}

.WashRack .Background circle {
  fill: rgb(199, 131, 227);
}

/* Start */

.WashRack .Start {
  background: #fafafa;
}

.WashRack .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .WashRack .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}
