.Contact {
  text-align: center;
  background: #fafafa;
}

.Contact .container {
  text-align: left;
  margin: 70px auto 150px;
  padding: 50px 70px 90px;
  background: #fff;
  display: inline-block;
  box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
  border-radius: 3px;
}

.Contact h1 {
  margin: 0;
  padding: 0;
}

.Contact form {
  width: 450px;
}

.Contact .status {
  background: rgba(46, 204, 113, .2);
  border-radius: 5px;
  padding: 15px;
  margin: 70px 0 50px;
  width: calc(450px - 30px);
  text-align: center;
}

.Contact .status.error {
  background: rgba(231, 76, 60, .2);
}

.Contact .status.error u,
.Contact .status.error a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
}

.Contact label {
  display: none;
  margin: 30px 0 -30px;
  font-size: 14px;
  padding: 0 0 3px 5px;
}

.noPlaceholders .Contact label {
  display: block;
}

.Contact input,
.Contact textarea {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: inherit;
  padding: 8px 15px;
  font-size: 14px;
  height: calc(35px - 16px);  /* Height - Padding */
  width: calc(100% - 30px - 2px);  /* Width - Padding - Border */
  margin: 30px 0 0;
  display: block;
}

.Contact input[name=firstname],
.Contact input[name=lastname] {
  width: calc(50% - 30px - 2px - 10px);  /* Width - Padding - Border - Margin */
  display: inline-block;
}

.noPlaceholders .Contact input[name=firstname],
.noPlaceholders .Contact input[name=lastname] {
  width: calc(100% - 30px - 2px);
  display: block;
  float: none;
}

.Contact input[name=lastname] {
  float: right;
}

.Contact textarea {
  height: 105px;
}

.Contact input[type=submit] {
  width: 150px;
  margin: 50px auto 0;
  cursor: pointer;
  background: #3F9AF7;
  color: #fff;
  border: 2px solid #3F9AF7;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  transition: color .2s,
    background .2s;
}

.Contact input[type=submit]:hover {
  color: #3F9AF7;
  background: none;
}

/*** Responsive Widths ***/

/* Mobile */
@media only screen and (max-width: 720px){
  .Contact .container {
    padding: 40px 30px 70px;
    width: calc(100% - 60px - 30px);  /* Width - Padding - Margin */
  }

  .Contact form {
    width: 100%;
  }

  .Contact .status {
    width: calc(100% - 30px);
  }

  .Contact input,
  .Contact textarea {
    font-size: 16px;  /* Prevent zoom in input focus on iOS */
  }

  .Contact input[name=firstname],
  .Contact input[name=lastname] {
    width: calc(100% - 30px - 2px);
    display: block;
    float: none;
  }
}
