.Home .Hero .content {
  margin: 150px auto 200px;
}

.Home .content_left {
  display: inline-block;
  vertical-align: top;
  max-width: 660px;
}

.Home .content_right {
  display: inline-block;
  vertical-align: top;
  padding-left: 25px;
  float: right;
  width: calc(100% - 685px);
  width: calc(100% - 685px - env(safe-area-inset-left) - env(safe-area-inset-right));
}

/*** Hero Form ***/

.Home .Hero .form {
  padding: 44px 0;
  display: inline-block;
  position: relative;
}

/* Button to Show Form */

.Home .Hero .form .buttons {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: none;
  margin-top: 10px;
}

.Home .Hero .demo_button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 2px solid rgb(230, 126, 34);
  background: rgb(230, 126, 34);
  border-radius: 5px;
  font-family: inherit;
  font-weight: 600;
  color: #fff;
  padding: 8px 15px;
  line-height: normal;
  font-size: 16px;
  height: 50px;
  min-width: 150px;
  cursor: pointer;
  transition: color .2s,
    background .2s;
}

.Home .Hero .demo_button:hover {
  background: #fff;
  color: rgb(230, 126, 34);
}

.Home .Hero button,
.Home .Hero input,
.Home .Hero .form .status {
  box-shadow: 0 7px 20px -5px rgb(0 0 0 / 40%);
}

.Home .Hero .form.show_button .buttons {
  display: inline-block;
}

.Home .Hero .form.show_button form {
  visibility: hidden;
}

.Home .Hero .form .buttons div {
  display: inline-block;
  margin-left: 50px;
  transition: color .2s;
  text-shadow: 0 3px 10px rgb(0 0 0 / 30%);
}

.Home .Hero .form .buttons div a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.Home .Hero .form .buttons div b {
  display: inline-block;
  text-align: right;
  width: 22px;
  transition: width .2s;
}

.Home .Hero .form .buttons div:hover {
  color: #ccc;
}

.Home .Hero .form .buttons div:hover b {
  width: 27px;
}

/* Form */

.Home .Hero .form form {
  position: relative;
  max-width: 400px;
}

.Home .Hero form label {
  display: none;
}

.noPlaceholders .Home .Hero form label {
  display: initial;
}

.noPlaceholders .Home .Hero form input.name {
  width: calc(100% - 30px) !important;
  margin-left: 0 !important;
}

.Home .Hero form input,
.Home .Hero form button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  font-family: inherit;
  padding: 0 15px;
  margin: 0;
  line-height: normal;
  font-size: 14px;
  height: 35px;
}

.Home .Hero form input {
  border-radius: 5px;
  background: #fff;
  width: calc(100% - 30px);
  margin-bottom: 20px;
}

.Home .Hero form input.name {
  width: calc(50% - 30px - 10px);
}

.Home .Hero form input.name:nth-of-type(2){
  margin-left: 20px;
}

.Home .Hero form button {
  border-radius: 5px;
  background: rgb(230, 126, 34);
  border: 2px solid rgb(230, 126, 34);
  min-width: 100%;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background .2s,
    color .2s;
  display: block;
  margin: auto;
}

.Home .Hero form button:hover {
  background: #fff;
  color: rgb(230, 126, 34);
}

/* Form Status */

.Home .Hero .form .status {
  position: absolute;
  z-index: 1;
  width: calc(100% - 60px);
  display: none;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  padding: 20px 30px 25px;
  color: #000;
}

.Home .Hero .form .status h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
}

.Home .Hero .form .status a {
  color: inherit;
  cursor: pointer;
}

.Home .Hero .form.show_success .success,
.Home .Hero .form.show_error .error {
  display: block;
}

.Home .Hero .form.show_success form,
.Home .Hero .form.show_error form {
  visibility: hidden;
}

/*** Responsive Widths ***/
/* Medium */
@media only screen and (max-width: 1024px){
  .Home .Hero .content {
    margin: 100px auto 150px;
  }

  .Home .content_left {
    max-width: 475px;
  }

  .Home .Hero .form form {
    max-width: none;
  }

  .Home .content_right {
    float: none;
    width: calc(100% - 500px);
    width: calc(100% - 500px - env(safe-area-inset-left) - env(safe-area-inset-right));
  }

  .Demo {
    margin: auto;
  }
}

/* Move Demo Below Hero Content */
@media only screen and (max-width: 850px){
  .Home .content_left {
    max-width: auto;
    display: block;
    margin: auto;
  }

  .Home .Hero .form .buttons {
    text-align: center;
  }

  .Home .Hero .form .buttons div {
    display: block;
    margin: 30px 0 0;
  }

  .Home .Hero .form .buttons div b,
  .Home .Hero .form .buttons div:hover b {
    width: auto;
    padding: 0 0 0 5px;
  }

  .Home .content_right {
    display: block;
    float: none;
    padding: 0;
    width: 100%;
  }

  .Demo {
    margin: auto;
  }

  /*** Background ***/
  .Background {
    left: 30%;
    bottom: 15%;
  }
}

/* Mobile */
@media only screen and (max-width: 720px){
  .Home .Hero .content {
    margin: 50px auto 150px;
  }
}
