.ServiceDriveDispatch {
  background: rgb(39, 174, 96);
}

.ServiceDriveDispatch .content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 100px 10px;
  text-align: center;
}

@supports (padding: env(safe-area-inset-top)) {
  .ServiceDriveDispatch .content {
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
  }
}

/* Hero */

.ServiceDriveDispatch .Hero {
  background: linear-gradient(rgb(39, 174, 96), rgb(28, 125, 68));
}

.ServiceDriveDispatch .Hero .content {
  margin: 10px auto 50px;
}

.ServiceDriveDispatch .Hero h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

/* Background */

.ServiceDriveDispatch .Background {
  left: 30%;
}

.ServiceDriveDispatch .Background circle {
  fill: rgb(39, 174, 96);
}

/* Start */

.ServiceDriveDispatch .Start {
  background: #fafafa;
}

.ServiceDriveDispatch .Start .content {
  padding-bottom: 150px;
}

/*** Responsive Widths ***/
/* Mobile */
@media only screen and (max-width: 720px){
  .ServiceDriveDispatch .Hero h1 {
    font-size: 35px;
    margin: 0 0 5px;
  }
}
