@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,800);

*, a, a * {
  outline: none;
  border: none;
}

body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
