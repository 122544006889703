/* Header */

.App > div:first-child {
  padding-top: 70px;  /* Space for Header */
}

/* Browser Workarounds */

a::-moz-focus-inner {
  border: 0;  /* Remove border on links in Firefox */
}

/* Headings */

h1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 35px;
  color: #2c3e50;
}

h2 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  color: #2c3e50;
}

/* Sections */

section {
  position: relative;
}

section .convex,
section .concave {
  position: absolute;
  height: 25px;
  width: 100%;
  z-index: 1;
  right: 0;
}

section .convex.top,
section .concave.top {
  top: -25px;
}

section .convex.bottom ,
section .concave.bottom {
  bottom: -25px;
}

section .convex.top,
section .concave.bottom {
  transform: rotate(180deg);
}

section .convex path,
section .concave path {
  fill: #fafafa;
}

/* Hero Section */

.Hero {
  background: linear-gradient(#3F9AF7, #0865C4 500px);
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.Hero .content {
  padding: 0 25px;
  z-index: 2;
  text-align: left;
}

@supports (padding: env(safe-area-inset-top)) {
  .Hero .content {
    padding-left: calc(env(safe-area-inset-left) + 25px);
    padding-right: calc(env(safe-area-inset-right) + 25px);
  }
}

.Hero .h_wrapper {
  display: inline-block;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  font-family: Raleway, "Open Sans", "Helvetica Neue", sans-serif;;
}

.Hero h1 {
  margin: 0 auto 30px;
  padding: 0;
  font-weight: 800;
  font-size: 70px;
  color: inherit;
  line-height: 1;
}

.Hero h2 {
  margin: 50px 0 20px;
  padding: 0;
  font-weight: 600;
  color: inherit;
  text-transform: uppercase;
  line-height: 1;
  font-size: 18px;
}

.Hero h3 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  max-width: 400px;
}
.Hero h3 span {
  font-weight: 600;
}

/* Items */

*.item {
  text-align: left;
  background: #fff;
  display: inline-block;
  box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
  border-radius: 3px;
  padding: 30px;
  margin: 15px;
  width: calc(100%/3 - 60px - 30px);
  vertical-align: top;
  position: relative;
}

.item h2 {
  margin: 0;
  padding: 0 0 10px;
  font-weight: 600;
  color: #2c3e50;
  font-size: 20px;
}

.item.new h2::after {
  content: "NEW";
  margin-left: 7px;
  padding: 1px 5px;
  background: #1abc9c;
  color: #fff;
  border-radius: 2px;
  font-size: 60%;
  vertical-align: middle;
}

/* Items that are Links */

a.item {
  min-height: 81px;
  text-decoration: none;
}

a.item .center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
}

a.item .center h2 {
  padding-bottom: 0 !important;
  padding-right: 15px;
  display: inline-block;
  position: relative;
}

a.item .center h2::after {
  content: "";
  height: 7px;
  width: 7px;
  border-top: 2px solid;
  border-right: 2px solid;
  display: inline-block;
  transform: rotate(45deg)
    translateY(-50%);
  position: absolute;
  right: 7px;
  top: 50%;
  transition: right .2s;
}

a.item:hover .center h2::after {
  right: 0;
}

/* Item Links */

.item a {
  color: #3F9AF7;
  text-decoration: none;
  transition: border .2s;
  border-bottom: 1px solid;
  border-color: rgba(255,255,255,0);
}

.item a:hover {
  border-color: #3F9AF7;
}

/* Show More Items */

.showMoreItems {
  display: none;
}

/*** Responsive Widths ***/
/* Medium */
@media only screen and (max-width: 1024px){
  /* Waves */

  section .convex,
  section .concave {
    height: 10px;
  }

  section .convex.top,
  section .concave.top {
    top: -10px;
  }

  section .convex.bottom ,
  section .concave.bottom {
    bottom: -10px;
  }

  /* Hero Section */

  .Hero .content {
    margin: 100px auto 200px;
  }

  .Hero h1 {
    font-size: 50px;
  }
}

/* Mobile */
@media only screen and (max-width: 720px){
  .item {
    width: calc(50% - 60px - 30px);
  }
}

/* Mobile Portrait */
@media only screen and (max-width: 550px){
  /* Headings */

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
  }

  /* Items */

  .item {
    display: block;
    margin: 30px 15px;
    width: calc(100% - 60px - 30px);
  }

  /* Show More Items */

  .showMoreItems {
    display: block;
    margin: 30px 0;
    position: relative;
  }

  .showMoreItems .button {
    position: absolute;
    top: 35px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #3F9AF7;
    padding: 7px 15px;
    border-radius: 10px;
    cursor: pointer;
  }

  .showMoreItems .fakeItem {
    background: #fff;
    box-shadow: 0 7px 20px -5px rgba(0,0,0,.1);
    border-radius: 3px 3px 0 0;
    padding: 30px;
    margin: 30px 15px 0;
    width: calc(100% - 60px - 30px);
    position: relative;
  }

  .showMoreItems .fakeItem::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    bottom: -30px;
    background: linear-gradient(rgba(250,250,250,0) 30px, #fafafa 60px);
  }

  .showMoreItems.show,
  .showMoreItems ~ .item {
    display: none;
  }

  .showMoreItems.show ~ .item {
    display: inherit;
  }

  /* Spacer Items */

  .SolutionItems .item.link,
  .SolutionItems .item.spacer {
    display: none;
  }
}
