.Demo {
  background: #fff;
  padding: 64px 5px 5px;
  margin-left: auto;
  max-width: 350px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 7px 20px -5px rgb(0 0 0 / 50%);
}

/*** Instructions ***/

.Demo .instructions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
  font-size: 14px;
}

/*** Header ***/

.Demo .header {
  padding: 9px;
  height: 26px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 10px 10px 0 0;
  text-align: center;
  background: rgb(230, 126, 34);
  z-index: 50;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

/* Buttons */

.Demo .header .buttons {
  display: inline-block;
  position: absolute;
  top: 9px;
}

.Demo .header .buttons.left {
  left: 8px;
}

.Demo .header .buttons.right {
  right: 8px;
}

.Demo .header .icon {
  fill: #fff;
  height: 22px;
  width: 22px;
  vertical-align: middle;
  padding: 0 7px;
  display: inline-block;
  position: relative;
}

/* Title */

.Demo .header .title {
  display: inline-block;
  margin: auto;
  font-size: 0;
  font-weight: 600;
  height: 26px;
  vertical-align: middle;
}

.Demo .header .title span {
  vertical-align: middle;
  font-size: 18px;
}

.Demo .header .title .icon {
  padding-right: 5px;
}

.Demo .icon svg {
  height: 100%;
  overflow: hidden;
}

/*** Cards ***/

.Demo .Card {
  display: inline-block;
  position: relative;
  vertical-align: top;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 4%);
  border-radius: 7px;
  margin: 0 10px 20px;
  font-size: 14px;
  padding: 15px 30px 15px 15px;
  width: calc(100% - 67px);
  background: #fff;
  color: #000;
}

/* With Customer */
.Demo .Card.Customer {
  background: rgb(230, 242, 255);
}

/* On Hold */
.Demo .Card.Hold {
  background: rgb(238, 238, 238);
  margin-bottom: 15px;
}

/*** Menu ***/

.Demo .Card::before {
  content: "⋮";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(153, 153, 153);
  line-height: 1;
  padding: 0;
}

.Demo .Card .fauxMenu {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 9px;
}

/*** Fields ***/

.Demo .Card .field {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  margin: 0 1px 2px;
  width: calc(100% - 2px);
  position: relative;
}

.Demo .Card .field span {
  position: relative;
}

/* Name */

.Demo .Card .field.name {
  width: calc(66.66% - 2px);
  font-size: 22px;
}

/* Status */

.Demo .Card .field.status {
  width: calc(33.33% - 2px);
  text-align: right;
}

.Demo .Card .field.status span {
  background: green;
  border-radius: 10px;
  color: #fff;
  padding: 1px 7px 2px;
  position: relative;
}

.Demo .Card.Customer .field.status span {
  background: rgb(52, 152, 219);
}

.Demo .Card.Hold .field.status span {
  background: #777;
}

/* Wait Time */

.Demo .Card .field.wait {
  color: rgb(119, 119, 119);
}

/* Customer Name */

.Demo .Card .field.customer {
  color: rgb(119, 119, 119);
}

/*** Tooltips ***/

.Demo .Tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.Demo .Tooltip.show {
  opacity: 1;
  visibility: visible;
  transition: visibility, opacity;
  transition-duration: .5s;
  transition-timing-function: ease-in;
}

/* Show Tooltips on Demo Hover */

.Demo:hover .Tooltip {
  opacity: 1;
  visibility: visible;
  transition: visibility .5s, opacity .5s;
}

.Demo:hover .Tooltip .indicator:hover ~ .tip {
  opacity: 1;
  visibility: visible;
  transition: visibility .3s, opacity .3s;
}

.Demo:hover .Tooltip .tip {
  opacity: 0;
  visibility: hidden;
}

/* Tooltip Content */

.Demo .Tooltip .tip {
  position: absolute;
  top: calc(100% + 15px);
  left: -1px;
  background: #333;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  width: 150px;
  z-index: 100;
}

.Demo .Tooltip.right .tip {
  left: -137px;
}

.Demo .Tooltip.bottom .tip {
  top: initial;
  bottom: calc(100% + 15px);
}

.Demo .Tooltip .tip::before {
  content: "";
  position: absolute;
  top: -7px;
  left: calc(50% - 7px);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #333;
}

.Demo .Tooltip.left .tip::before {
  left: 7px;
}

.Demo .Tooltip.right .tip::before {
  left: initial;
  right: 7px;
}

.Demo .Tooltip.bottom .tip::before {
  top: initial;
  bottom: -7px;
  border-top: 7px solid #333;
  border-bottom: none;
}

/* Tooltip Indicators */

.Demo .Tooltip .indicator {
  content: "";
  position: absolute;
  top: -6px;
  left: -4px;
  width: 30px;
  height: 30px;
  background: rgba(0,0,0,.1);
  border: 2px solid #f2f2f2;
  border-top-color: rgb(41,128,185);
  border-bottom-color: rgb(41,128,185);
  box-shadow: 0 0 0 1px rgb(41,128,185),
    0 0 0 1px rgb(41,128,185) inset;
  border-radius: 50%;
  animation: animateIndicators 7s linear infinite;
}

@keyframes animateIndicators {
  to { transform: rotate(-360deg); }
}

/* Position Adjustments */

.Demo .header .icon .Tooltip {
  left: 5px;
}
